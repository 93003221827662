import Link from "next/link";
import React from "react";

import { Fade } from "react-reveal";
import AnimatedDots from "../common/AnimatedDots";

function MajorFeatures({ NoBorder }) {
  return (
    <section
      className={`container  ${
        NoBorder === true
          ? " py-16 md:mt-16"
          : "py-12 md:py-[98px]  lg:py-[118px] "
      } `}
    >
      <div>
        <div className=" dark:bg-jacarta-900  text-center  ">
          <Fade ssrReveal top delay={350}>
            <div className="grid lg:grid-cols-2 gap-6 md:gap-12 mb-4">
              <div>
                <AnimatedDots />
                <h2 className=" text-start font-display text-2xl md:text-[38px] text-jacarta-900 dark:text-white tracking-widest">
                  How does NetZylo <br className="hidden md:flex " /> work?
                </h2>
              </div>
              <div>
                <p className="text-start text-[18px] xl:text-lg text-jacarta-900 dark:text-white">
                  Buy a TLD, set up onboard flows, create a branded wallet,
                  launch an Al agent & token, engage users with NFTS, and build
                  a complete Web3 ecosystem-all with no code.
                </p>
                <Fade ssrReveal bottom delay={350}>
                  <div className="flex flex-col md:flex-row gap-6 md:gap-8 mt-10">
                    <Link
                      href="/"
                      className="min-w-[200px] xl:min-w-[230px] items-center  justify-center rounded-md text-[18px] font-medium  bg-gradient-to-tr from-hotPink via-purplish to-accent py-[22px] px-[32px] cursor-pointer button z-[2] transition-all duration-500 text-white hover:-translate-y-1 text-center"
                    >
                      Start Now
                    </Link>
                    <Link
                      href="/"
                      className="min-w-[200px] xl:min-w-[230px] items-center flex justify-center rounded-md text-[18px] font-medium dark:bg-jacarta-800  hover:bg-jacarta-base cursor-pointer  py-[22px] px-[32px] z-[2] transition-all duration-500 hover:-translate-y-1 bg-gray-300 text-white dark:hover:bg-white dark:hover:text-gray-300"
                    >
                      Learn Free
                    </Link>
                  </div>
                </Fade>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
}

export default MajorFeatures;
