import Image from 'next/image'
import React from 'react'

function AnimatedDots({type, theme, className}) {
  return (
    <div className={`flex items-center gap-0.5 mb-4 h-3 group relative ${className}`}>
    <span className="w-3 h-3 rounded-full bg-jacarta-200  dark:bg-jacarta-600  "></span>
    <span className="w-3 h-3 rounded-full bg-jacarta-200  dark:bg-jacarta-600  "></span>

    <span className="w-3 h-3 rounded-full bg-gradient-to-tr from-hotPink via-purplish to-accent absolute top-0 left-0 animate-swap"></span>
    {/* {type === "z-wallet" && theme === "dark" ? (
      <Image
        src="/images/z-wallet-dark.svg"
        width={150}
        height={30}
      />
    ) : (
      <Image
        src="/images/z-wallet-light.svg"
        width={150}
        height={30}
      />
    )} */}
  </div>
  )
}

export default AnimatedDots